<template>
  <div>
    <br />
    <div>
        <h1>Customer Feedback</h1>
    </div>
    <v-row>
        <v-col cols="12" md="12">
            <v-data-table
                :headers="headers"
                :items="feedback"
                :items-per-page="5"
                class="elevation-1">
                <template v-slot:item.controls="props">
                    <v-btn icon color="red" @click="deleteItem(props.item)">
                    <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-col>   
    </v-row>
  </div>    
</template>

<script>
import Service from '@/services/Service.js';
import Vue from 'vue';

import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  data() {
    return {
      feedback: [],
      monitorSystem: null,
      props: null,
      toDateMenu: null,
      fromDateMenu: null,
      fromDateVal: null,
      toDateVal: null,
      items: ['Solaredge', 'Fronius', 'Solarlog', 'Locus', 'Also', 'Enphase', 'Powerdash', "GMP", "Allearth", "Auroravision", "Chint", "Egauge", "Pika", "Smasunnyportal", "Solectria"],
      headers: [
        { text: 'Priority', value: 'priority' },
        { text: 'Date', value: 'date' },
        { text: 'User', value: 'user'},
        { text: "Suggestion", value: "suggestion", sortable: false },
        { text: "Delete", value: "controls", sortable: false }
      ],
    };
  },
  mounted() {
    document.title = "Admin"
  },
  beforeMount(){
    // var date = new Date();
    // this.toDateVal = this.$moment(date).format('YYYY-MM-DD');
  },
  created() {
    var date = new Date();
    this.toDateVal = this.$moment(date).format('YYYY-MM-DD');
    this.initialize();
  },
  methods: {
    initialize() {
      Service.getFeedback()
        .then((response) => {
          const f = response.data
          this.feedback = f.map((obj) => {
            const newObj = { ...obj }; 
            newObj.date = newObj.date.slice(0, 10);
            return newObj;
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading filter data`, {position: "top-right"})
          console.error(error)
        })
    },   
    deleteItem(item) {
      Service.deleteFeedback(item.id)
        .then((response) => {
          Vue.$toast.success('Feedback deleted', {position: "top-right"})
          this.initialize()
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading filter data`, {position: "top-right"})
          console.error(error)
        })
    }      
  },
  computed: {

  },
};
</script>

<style>
</style>